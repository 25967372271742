<!--
 * @Author: lbh
 * @Date: 2022-09-20 16:10:04
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-12 17:10:33
 * @Description: 中間件
-->
<template>
  <div
    class="can-doing-box px-can-doing-box"
    @click="clickItem('box')"
    :class="`edit_${Xindex}_box ${showPhone && configs.phoneImage ? 'showPhone' : ''}`"
    :style="`padding:${configs.topPadding}px ${configs.padding}px`"
  >
    <template v-if="showPhone && configs.phoneImage">
      <div
        class="img-box"
        @click="$util.route.go(configs.phoneImageGo)"
      >
        <img :src="configs.phoneImage" />
      </div>

    </template>
    <template v-else>
      <img
        class="bigImg"
        :src="configs.image"
        :alt="configs.alt"
      />
      <div
        class="right-b"
        :style="`margin-left:${configs.nPadding}px`"
      >
        <h2
          class="title"
          v-html="$util.rex.getHtml(configs[`${nowL}title`] || configs.title)"
        ></h2>
        <h1
          class="sub-title"
          v-html="$util.rex.getHtml(configs.subTitle[`${nowL}text`] || configs.subTitle.text)"
          :style="`color:${configs.subTitle.color}`"
        ></h1>
        <div class="btns">
          <selfButton :go="configs.btn1.go">{{configs.btn1[`${nowL}title`] || configs.btn1.title}}</selfButton>
          <selfButton
            theme="plain"
            :go="configs.btn2.go"
          >{{configs.btn2[`${nowL}title`] || configs.btn2.title}}</selfButton>
        </div>
        <div class="other-box">
          <div v-for="(item,index) in configs.arr">
            <img src="../../assets/do-img/tedian.png" />
            <span v-html="$util.rex.getHtml(item[`${nowL}title`] || item.title)"></span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import langMixin from '../../mixins/lang';
export default {
  name: "selfCanDoing",
  mixins: [langMixin],
  props: {
    configs: {
      default () {
        return {
          image: '',
          title: '',
          subTitle: {
            text: '',
            color: '',
          },
          btn1: {
            title: '',
            go: '',
          },
          btn2: {
            title: '',
            go: '',
          },
          arr: [{ title: '' }],
        }
      }
    },
    Xindex: {
      default () {
        return '';
      },
    },
  },
  data () {
    return {
      isEdit: false,
      showOffer: false,
      showPhone: false,
    }
  },
  created () {
    this.isEdit = this.$store.getters.getIsEdit;
    this.$listen.listen('canDoing', e => {
      if (e < 500) this.showPhone = true;
      else this.showPhone = false;
    })
  },
  methods: {
    clickItem (e, x) {
      if (this.isEdit) {
        this.$emit('clickItem', e, x);
      }
    },
  }
}
</script>

<style lang="less" scoped>
.can-doing-box {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fafafa;
  padding: 60px 234px !important;

  .bigImg {
    max-height: 700px;
  }
  .right-b {
    .title {
      font-size: 42px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #101010;
    }
    .sub-title {
      font-size: 60px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
    }
    .btns {
      display: flex;
      margin: 25px 0 70px;
      & /deep/ .self-button-box {
        &:nth-child(2) {
          margin-left: 10px;
        }
      }
    }
    .other-box {
      font-size: 28px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #101010;
      display: flex;
      flex-wrap: wrap;
      div {
        display: flex;
        align-items: center;
        width: 50%;
        margin-bottom: 25px;
        white-space: nowrap;
        img {
          margin-right: 6px;
          width: 28px;
          height: 28px;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .px-can-doing-box {
    padding-left: 20px !important;
    padding-right: 20px !important;
    &.showPhone {
      padding: 0 !important;
    }
    .img-box {
      img {
        max-width: 100%;
      }
    }
    .bigImg {
      max-width: 45%;
      max-height: 100% !important;
    }
    .right-b {
      margin-left: 15px !important;
      .title {
        font-size: 24px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #101010;
      }
      .sub-title {
        font-size: 36px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: var(--themeColor);
        line-height: 44px;
      }
      .btns {
        & /deep/ .self-button-box {
          &:nth-child(2) {
            margin-left: 10px;
          }
        }
      }
      .other-box {
        display: inherit;
        div {
          font-size: 16px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #101010;
          width: 100%;
          img {
            width: 16px;
            height: 16px;
            margin-right: 2px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .px-can-doing-box {
    padding: 25px 20px !important;
    &.showPhone {
      padding: 0 !important;
    }
    .img-box {
      img {
        max-width: 100%;
      }
    }
  }
}
</style>